<template>
  <div class="input-search">
    <form @submit.prevent.stop="submit">
      <button type="button" v-if="search && search.length" @click="reset">×</button>
      <code v-show="code">{{code}}</code>
      <input ref="input" v-model="search" :placeholder="placeholder">
      <button>🔎</button>
    </form>
  </div>
</template>

<script>
import {onMounted, onUnmounted, ref} from 'vue'

export default {
  props: {
    code: {
      type: String,
      require: false,
      default: ''
    },
    placeholder: {
      type: String,
      require: false,
      default: ''
    }
  },
  setup(props, {emit}) {
    let search = ref('')
    const input = ref(null)
    const keydown = (event) => {
      if (event.code === props.code) {
        search.value = ''
        input.value.focus()
      }
      if (event.code === 'Escape') input.value.blur()
    }

    onMounted(() => {
      document.addEventListener('keydown', keydown)
    })

    onUnmounted(() => {
      document.removeEventListener('keydown', keydown)
    })

    const submit = () => {
      input.value.blur()
      emit('submit', {search: search.value})
    }

    const reset = () => {
      search.value = ''
      emit('submit', {search: ''})
    }
    return {
      search, input,
      submit, reset,
    }
  }
}
</script>

<style lang="less" scoped>
.input-search {
  form {
    position: relative;
    z-index: 1;
    display: flex;
    padding: 0 10px;
  }

  input {
    margin: 0;
    position: relative;
    z-index: 1;
    width: calc(100% - 40px);
  }

  button {
    position: relative;
    z-index: 0;
    width: 41px;

    &:first-child {
      width: 41px;
      margin-right: -1px;

      & ~ code {
        left: 55px;
      }

      & ~ input {
        width: calc(100% - 80px);
      }
    }

    &:last-child {
      margin-left: -1px;
    }
  }

  code {
    position: absolute;
    z-index: 2;
    top: -5px;
    left: 15px;
    background: #a0a0a0;
    color: #fff;
    font-size: 10px;
    padding: 0 5px;
  }

}
</style>
