<template>
  <ul class="paginator" v-show="pages > 1">
    <li><button :disabled="current === 1" @click="submit(current-1)">◄</button></li>
    <li v-for="i in data" :class="{active:i.page===current}">
      <button @click="submit(i.page)" v-if="!i.disabled">{{i.page}}</button>
      <div v-else>.</div>
    </li>
    <li><button :disabled="current+1 > pages" @click="submit(current+1)">►</button></li>
  </ul>
</template>

<script>
export default {
  props: {
    current: {
      type: Number,
      require: false,
      default: 1
    },
    per_page: {
      type: Number,
      require: false,
      default: 20
    },
    total: {
      type: Number,
      require: false,
      default: 1
    }
  },
  setup(props, {emit}) {
    const data = []
    const pages = Math.ceil(props.total / props.per_page)
    for (let i = 1; i <= pages; i++) {
      if(i <= 2 && i !== props.current) data.push({page: i, disabled: false})
      if(i === 3 && props.current < 3) data.push({page: i, disabled: false})
      if(i === props.current) data.push({page: i, disabled: false})
      if(i === pages - 2 && props.current > pages - 2) data.push({page: i, disabled: false})
      if((pages - 1) <= i && i !== props.current) data.push({page: i, disabled: false})

      if(
        (props.current > 3 && i === props.current-1 && i > 2 && i < pages-2) ||
        (props.current < pages-2 && i === props.current+1 && i > 3) ||
        (pages > 5 && props.current < 3 && i === 4) ||
        (pages > 5 && props.current > pages-2 && i === pages-4)
      ) data.push({page: i, disabled: true})
    }

    const submit = (page) => {
      emit('submit', {page})
    }

    return {data, pages, submit}
  }
}
</script>

<style lang="less" scoped>
.paginator {
  list-style: none;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  width: 320px;
  margin: 10px auto;

  li {
    &.active button {
      color: darkseagreen;
    }

    button {
      width: 35px;
      font-size: 12px;
      text-align: center;
      padding: 0;
    }

    div {
      height: 30px;
      line-height: 30px;
    }
  }
}
</style>
