<template>
  <div class="empty-data">
    <template v-if="text">{{text}}</template>
    <template v-else>Нет результатов</template>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      require: false
    }
  }
}
</script>

<style lang="less" scoped>
.empty-data {
  text-align: center;
  margin: 20px 10px;
  font-size: 14px;
  color: darkred;
}
</style>
