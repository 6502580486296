<template>
  <div class="page-supplies">
    <InputSearch code="F1" placeholder="Поиск по номеру / поставщику" @submit="getData"/>

    <template v-if="!load && supplies.data.length">
      <table>
        <tr>
          <th>
            №
          </th>
          <th>
            Документ
          </th>
          <th></th>
        </tr>
        <tr v-for="g in supplies.data" :key="g.id">
          <td class="text-center">
            <strong>{{ g.name }}</strong><br>
            <small>
              {{g.goods_count}} наимен.<br>
              <strong>{{g.sum}} руб</strong></small>
          </td>
          <td style="line-height: 1.6; word-break: break-word; font-size: 12px">
            {{ g.date }}<br>
            {{ g.legal }}<br>
            {{ g.invoice }}
          </td>
          <td>
            <button @click="$router.push('/supplies/'+g.id)">⇒</button>
          </td>
        </tr>
      </table>
      <Paginator :current="+supplies.current_page" :per_page="+supplies.per_page" :total="+supplies.total" @submit="getData"/>
    </template>
    <EmptyData v-else-if="!load"/>

  </div>
</template>

<script>
import InputSearch from "@/components/InputSearch";
import {ref} from "vue";
import EmptyData from "@/components/EmptyData";
import Paginator from "@/components/Paginator";

export default {
  components: {Paginator, EmptyData, InputSearch},
  setup(props, {emit}) {
    emit('meta', {title: 'Поставки', back: '/'})

    let load = ref('Загрузка...')
    let supplies = ref([])
    let search = ''

    const getData = async (params = {}) => {
      params.page = params.page ? params.page : 1
      params.search !== undefined ? search = params.search : params.search = search

      load.value = 'Загрузка...'
      let {data} = await axios.get('v1/supplies', {params})
      supplies.value = data
      load.value = ''
    }
    getData()

    return {
      load, supplies, getData
    }
  }
}
</script>

<style lang="less">
.page-supplies {

}
</style>
